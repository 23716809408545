import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { ChakraBaseProvider, extendBaseTheme } from '@chakra-ui/react';
import { withEmotionCache } from '@emotion/react';
import { useContext, useEffect } from 'react';
import { json, type MetaFunction, type LinksFunction, type LoaderFunction } from '@remix-run/node';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from '@remix-run/react';

import { ServerStyleContext, ClientStyleContext } from './context';
import theme from './theme';

export const meta: MetaFunction = () => {
  return [
    { title: 'Lockable' },
    { property: 'og:title', content: 'Lockable' },
    { property: 'og:description', content: 'You have a secret waiting for you 🤐' },
    { property: 'og:image', content: 'https://lockable.co/share.jpg' },
  ];
};

export const links: LinksFunction = () => {
  return [];
};

export const loader: LoaderFunction = async ({ params }) => {
  return json({
    authorize: {
      authData: {
        clientKey: process.env.AUTHORIZE_CLIENT_KEY,
        apiLoginID: process.env.AUTHORIZE_LOGIN_ID,
      },
      url: process.env.AUTHORIZE_JS_URL,
    },
    firebaseCreds: JSON.parse(process.env.FIREBASE_CONFIG || '{}'),
  });
};

interface DocumentProps {
  children: React.ReactNode;
}

const Document = withEmotionCache(({ children }: DocumentProps, emotionCache) => {
  const serverStyleData = useContext(ServerStyleContext);
  const clientStyleData = useContext(ClientStyleContext);
  const { authorize, firebaseCreds } = useLoaderData<typeof loader>();

  // Only executed on client
  useEffect(() => {
    // re-link sheet container
    emotionCache.sheet.container = document.head;
    // re-inject tags
    const tags = emotionCache.sheet.tags;
    emotionCache.sheet.flush();
    tags.forEach((tag) => {
      (emotionCache.sheet as any)._insertTag(tag);
    });
    // reset cache to reapply global styles
    clientStyleData?.reset();
  }, []);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        {serverStyleData?.map(({ key, ids, css }) => (
          <style
            key={key}
            data-emotion={`${key} ${ids.join(' ')}`}
            dangerouslySetInnerHTML={{ __html: css }}
          />
        ))}
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.firebaseCreds = ${JSON.stringify(firebaseCreds)};`,
          }}
        />
        <script src={authorize.url} />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.authorizeAuthData = ${JSON.stringify(authorize.authData)};`,
          }}
        />
      </body>
    </html>
  );
});

const avaTheme = extendBaseTheme(theme);

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  return (
    <Document>
      <ChakraBaseProvider theme={avaTheme} resetCSS>
        <Outlet />
      </ChakraBaseProvider>
    </Document>
  );
}
